import { decodeQueryParamToObject } from '~/utils/url';

export function PaymentCallbackBar() {
  const paymentJson = sessionStorage.getItem('PaymentCallbackBar');
  let payment = null;

  if (!paymentJson) {
    payment = decodeQueryParamToObject<{
      status: 'cancel' | 'success';
      message: string;
    }>('payment');

    console.log('--------- DECODED -----------', JSON.stringify(payment));

    if (payment) {
      sessionStorage.setItem('PaymentCallbackBar', JSON.stringify(payment));
    }
  } else {
    try {
      payment = JSON.parse(paymentJson);
      sessionStorage.removeItem('PaymentCallbackBar');
    } catch (error) {
      payment = null;
    }
  }

  if (!payment || !payment.message) {
    return undefined;
  }

  return (
    <div
      class="md:py-4 text-center bg-gold-200 text-sky-900 font-semibold
        tracking-wide"
    >
      {payment.message}
    </div>
  );
}
