import { splitProps, type ParentProps } from 'solid-js';
import { Author, type AuthorDetails } from './Author';

export type TopNavProps = {
  creatorName?: string;
  class?: string;
  style?: string;
  author: AuthorDetails;
};

export function FlipTopNav(props: ParentProps<TopNavProps>) {
  const [, rest] = splitProps(props, ['creatorName', 'class']);

  return (
    <div
      data-component="TopNav"
      data-part="page-info"
      class={`flex justify-between items-center w-full font-body ${props.class}`}
      {...rest}
    >
      <div data-part="page-info" class="flex-row grow">
        <Author author={props.author} class="w-fit" showFollowers />
      </div>
    </div>
  );
}
